import { useSiteBatteryAnalysis } from "@inrange/building-manager-api-client";
import {
  PartialSiteAllowStringValues,
  Site,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import {
  buildSitePayload,
  SiteCalculationsContext,
  useContextTS,
} from "@inrange/shared-components";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Loading from "../../Loading";
import BatteryResults from "./BatteryResults";
import BatterySettings from "./BatterySettings";
import { roundToDp } from "./utils";

const Battery = ({
  site,
  setSite,
  currencyCode,
  isOwnerOccupied,
}: {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
  currencyCode: string;
  isOwnerOccupied: boolean;
}) => {
  const { errors, siteCalculations } = useContextTS(SiteCalculationsContext);
  const [batteryAnalysisPayload, setBatteryAnalysisPayload] = useState<
    { site: Partial<Site> } | undefined
  >(undefined);
  const [triggerBatteryRequest, setTriggerBatteryRequest] = useState(false);
  const [siteInLastBatteryRequest, setSiteInLastBatteryRequest] = useState<
    SiteAllowStringValues | undefined
  >(undefined);
  const [isAnalysisStale, setIsAnalysisStale] = useState<boolean>(false);
  const [capacityDebugData, setCapacityDebugData] = useState<any>(undefined);
  const [results, setResults] = useState<any[] | undefined>(undefined);
  const [baseline, setBaseline] = useState<any | undefined>(undefined);

  const testMode = !!(!import.meta.env.PROD && import.meta.env.VITE_TEST_MODE);
  const { batteryQueries } = useSiteBatteryAnalysis({
    onlySyntheticBatteries: testMode,
    payload: batteryAnalysisPayload,
  });
  const batteryQueriesRef = useRef(batteryQueries);
  batteryQueriesRef.current = batteryQueries;

  const siteRef = useRef<any>();
  siteRef.current = site;

  useEffect(() => {
    // Consume the calculated battery values and update the site state
    const update: any = {};
    let updateCount = 0;
    for (const field of [
      "batteryEnergyFlowPayloadHash",
      "batteryEnergyFlowFileVersionId",
    ]) {
      if (
        siteCalculations[field] &&
        siteCalculations[field] !== siteRef.current[field]
      ) {
        update[field] = siteCalculations[field];
        updateCount += 1;
      }
    }
    if (
      // Only update the battery cost from the calculations output
      // when the specs change and hence we have a new inferred price
      updateCount > 0 &&
      siteCalculations.costInputsBattery.batterySpec &&
      siteCalculations.costInputsBattery.batterySpec.hardwareCost &&
      siteCalculations.batteryLastPriceInputs !==
        siteRef.current.batteryLastPriceInputs
    ) {
      update["costInputsBattery"] = {
        ...siteRef.current.costInputsBattery,
        batterySpec: {
          ...siteRef.current.costInputsBattery.batterySpec,
          hardwareCost:
            siteCalculations.costInputsBattery.batterySpec.hardwareCost,
        },
        // Hardware cost is 70% of the total
        // Install cost is 30% of the total
        installationCost: roundToDp(
          (3 / 7) * siteCalculations.costInputsBattery.batterySpec.hardwareCost,
          2
        ),
      };
      update["batteryLastPriceInputs"] =
        siteCalculations.batteryLastPriceInputs;
      updateCount += 1;
    }
    if (updateCount > 0) {
      setSite(update);
    }
  }, [setSite, siteCalculations]);

  const withoutBatteryAttributes = (site: any) => {
    const {
      // Rest of the battery attributes are re-calculated as part of the analysis and so are irrelevant to the comparison
      batteryEnergyFlowPayloadHash: _batteryEnergyFlowPayloadHash,
      batteryEnergyFlowFileVersionId: _batteryEnergyFlowFileVersionId,
      batteryLastPriceInputs: _batteryLastPriceInputs,
      costInputsBattery: _costInputsBattery,
      // Also ignore notes field as this doesn't impact the analysis
      notes: _notes,
      ...restSite
    } = site;
    return { ...restSite };
  };

  useEffect(() => {
    if (triggerBatteryRequest) {
      for (const query of batteryQueriesRef.current) {
        query.refetch();
      }
      setTriggerBatteryRequest(false);
    }
  }, [triggerBatteryRequest]);

  const isBatteryAnalysisLoading = batteryQueries.some(
    (query) => query.isFetching
  );
  const isBatteryAnalysisLoaded = batteryQueries.every(
    (query) => query.isSuccess && !query.isFetching
  );
  const isBatteryAnalysisError = batteryQueries.some((query) => query.isError);

  useEffect(() => {
    if (isBatteryAnalysisLoaded) {
      const data = batteryQueriesRef.current.reduce(
        (acc: any, query: any) => {
          acc["batteryResults"] = acc["batteryResults"].concat(
            query.data.batteryResults
          );
          acc["baseline"] = query.data.baseline;
          acc["capacityMwh"] = query.data.capacityMwh;
          return acc;
        },
        {
          batteryResults: [],
          baseline: undefined,
          capacityMwh: undefined,
        }
      );
      setResults(data["batteryResults"]);
      setBaseline(data["baseline"]);
      setCapacityDebugData(data["capacityMwh"]);
    }
  }, [isBatteryAnalysisLoaded]);

  useEffect(() => {
    // If the site changes, mark results as stale
    if (
      siteInLastBatteryRequest &&
      !_.isEqual(
        withoutBatteryAttributes(site),
        withoutBatteryAttributes(siteInLastBatteryRequest)
      )
    ) {
      setIsAnalysisStale(true);
    }
  }, [site, siteInLastBatteryRequest]);

  const disableButtonDueToErrors = Object.keys(errors).length > 0;

  const onClickAnalyze = async () => {
    setIsAnalysisStale(false);
    setSiteInLastBatteryRequest(site);

    const sitePayload = {
      site: buildSitePayload(site as unknown as Partial<Site>),
    };
    setBatteryAnalysisPayload(sitePayload);
    setTriggerBatteryRequest(true);
  };

  return (
    <Card body className="mt-2">
      <h6
        id="battery-section"
        data-testid={"battery-section"}
        style={{ fontWeight: "bold" }}
      >
        Battery
      </h6>

      <BatterySettings
        site={site}
        setSite={setSite}
        currencyCode={currencyCode}
      />

      <h6 style={{ fontWeight: "bold" }}>Analysis</h6>

      <Row>
        <Col sm={9}>
          Analysis is constrained based on the value of “Applied grid capacity”
          in the DNO and network section.
        </Col>
        <Col sm={3} className="d-grid">
          <Button
            variant="success"
            onClick={onClickAnalyze}
            disabled={
              !isAnalysisStale &&
              (disableButtonDueToErrors ||
                isBatteryAnalysisLoading ||
                results !== undefined)
            }
            data-testid={"run-battery-analysis"}
          >
            Run analysis
          </Button>
        </Col>
      </Row>

      {isAnalysisStale && (
        <Card.Text className={`text-danger mt-3`}>
          Site has been updated since results were generated. Please run a new
          analysis.
        </Card.Text>
      )}

      <div style={{ marginTop: "20px" }}>
        {isBatteryAnalysisLoading && <LoadingResults />}
        {isBatteryAnalysisError && (
          <div>There was an error running the analysis</div>
        )}
        {!isBatteryAnalysisLoading && results !== undefined && (
          <>
            {results.length > 0 && (
              <div data-testid={"battery-results-table"}>
                <BatteryResults
                  site={site}
                  setSite={setSite}
                  baseline={baseline}
                  capacityDebugData={capacityDebugData}
                  results={results}
                  currencyCode={currencyCode}
                  isOwnerOccupied={isOwnerOccupied}
                />
              </div>
            )}
            {results.length == 0 && (
              <div>No results with positive IRR found</div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default Battery;

const LoadingResults = () => {
  const label = (
    <p className="text-center">
      Running analysis...
      <br />
      This can take up to 60 seconds. Don&apos;t refresh the page.
    </p>
  );
  return <Loading label={label} height="490px" />;
};
