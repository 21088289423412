import {
  PartialSite,
  PartialSiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";

export interface ChangedDefaults {
  beforeDefaults: PartialSite;
  afterDefaults: PartialSite;
}

export const selectChangedDefaults = (
  beforeDefaults: PartialSite,
  afterDefaults: PartialSite
) => {
  const result: { beforeDefaults: PartialSite; afterDefaults: PartialSite } = {
    beforeDefaults: {},
    afterDefaults: {},
  };
  for (const key in afterDefaults) {
    if (typeof afterDefaults[key] === "object") {
      for (const subKey in afterDefaults[key]) {
        if (afterDefaults[key][subKey] !== beforeDefaults[key][subKey]) {
          if (!result.beforeDefaults[key]) {
            result.beforeDefaults[key] = {};
            result.afterDefaults[key] = {};
          }
          result.beforeDefaults[key][subKey] = beforeDefaults[key][subKey];
          result.afterDefaults[key][subKey] = afterDefaults[key][subKey];
        }
      }
    } else if (afterDefaults[key] !== beforeDefaults[key]) {
      result.beforeDefaults[key] = beforeDefaults[key];
      result.afterDefaults[key] = afterDefaults[key];
    }
  }
  return result;
};

export const isEmptyDiff = (diff: {
  beforeDefaults: PartialSite;
  afterDefaults: PartialSite;
}) => {
  return Object.keys(diff.beforeDefaults).length === 0;
};

const compareValuesHandleStringToNumber = (a: unknown, b: unknown) => {
  if (typeof a === "string" && typeof b === "number") {
    return parseFloat(a) === b;
  }
  if (typeof a === "number" && typeof b === "string") {
    return a === parseFloat(b);
  }
  return a === b;
};

export const isSafeToApplyNewDefaults = (
  site: PartialSiteAllowStringValues,
  updatedDefaults: ChangedDefaults
): boolean => {
  for (const key in updatedDefaults.beforeDefaults) {
    if (typeof updatedDefaults.beforeDefaults[key] === "object") {
      for (const subKey in updatedDefaults.beforeDefaults[key]) {
        if (
          !compareValuesHandleStringToNumber(
            site[key][subKey],
            updatedDefaults.beforeDefaults[key][subKey]
          ) &&
          !compareValuesHandleStringToNumber(
            site[key][subKey],
            updatedDefaults.afterDefaults[key][subKey]
          )
        ) {
          return false;
        }
      }
    } else if (
      !compareValuesHandleStringToNumber(
        site[key],
        updatedDefaults.beforeDefaults[key]
      ) &&
      !compareValuesHandleStringToNumber(
        site[key],
        updatedDefaults.afterDefaults[key]
      )
    ) {
      return false;
    }
  }
  return true;
};
