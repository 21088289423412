import styled from "styled-components";
import SimpleButton from "./buttons/SimpleButton";

const DesktopOnly: React.FC = () => {
  return (
    <Container>
      <Title>Welcome</Title>
      <Text>
        {"We've"} designed this experience specifically for larger devices. To
        get the most out of it, please open <strong>app.inrange.io</strong> from
        your laptop or desktop computer.
      </Text>
      <SimpleButton
        label={"Visit our website"}
        onClick={() => window.open("https://www.inrange.io/")}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 10px;
  max-width: 552px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
`;

const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 54px;
  letter-spacing: -0.02em;
  color: #171717;
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.01em;
  color: #000000;
`;

export default DesktopOnly;
