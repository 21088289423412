import {
  AggregateEnergyFlow,
  AllowStringForNumbers,
  BatterySpec,
} from "@inrange/building-manager-api-client/models-site";
import React, { ReactNode } from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components";
import { formatNumber, parseAsNumber } from "./utils";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const BatteryTooltip: React.FC<{
  batterySpec: AllowStringForNumbers<BatterySpec>;
  energyFlowAnnual: AggregateEnergyFlow;
  energyFlowMonthly: {
    [month: number]: AggregateEnergyFlow;
  };
  children: ReactNode;
}> = ({ batterySpec, energyFlowAnnual, energyFlowMonthly, children }) => {
  const maxChargeKwh = energyFlowAnnual.batteryMaxFinalCharge;
  const usableDischargeMwhPerCycle =
    parseAsNumber(batterySpec.capacityMwh) *
    parseAsNumber(batterySpec.count) *
    parseAsNumber(batterySpec.depthOfDischarge);
  const oneCyclePerDayAnnualUsableDischargeMwh =
    usableDischargeMwhPerCycle * 366;
  const warrantyCyclesYearOne = Math.round(
    (parseAsNumber(batterySpec.warranty?.periodCycles) || 6000) /
      (parseAsNumber(batterySpec.warranty?.periodYears) || 10)
  );
  const getDaysInMonth = (month: number): number => {
    return new Date(2024, month, 0).getDate();
  };
  return (
    <TooltipContainer>
      {children}
      <TooltipContent>
        <ul>
          <li>
            Usable / used / unused (MWh/year): <br />
            {formatNumber(
              oneCyclePerDayAnnualUsableDischargeMwh,
              0,
              1,
              false
            )}{" "}
            /{" "}
            {formatNumber(
              energyFlowAnnual.batteryDischarge / 1000,
              0,
              1,
              false
            )}{" "}
            /{" "}
            {formatNumber(
              Math.max(
                0,
                oneCyclePerDayAnnualUsableDischargeMwh -
                  energyFlowAnnual.batteryDischarge / 1000
              ),
              0,
              1,
              false
            )}
          </li>
          <li>
            Max charge (whole year) (MWh):{" "}
            {formatNumber(maxChargeKwh / 1000, 0, 3, false)}
          </li>
          <li>
            Cycles:{" "}
            {formatNumber(
              energyFlowAnnual.batteryDischarge /
                1000.0 /
                usableDischargeMwhPerCycle,
              0,
              0,
              false
            )}
            <ul>
              <li>
                {formatNumber(
                  (energyFlowAnnual.batteryDischarge /
                    1000.0 /
                    usableDischargeMwhPerCycle /
                    366) *
                    100,
                  0,
                  1,
                  false
                )}
                % of 366 daily cycles/year
              </li>
              <li>
                {formatNumber(
                  (energyFlowAnnual.batteryDischarge /
                    1000.0 /
                    usableDischargeMwhPerCycle /
                    warrantyCyclesYearOne) *
                    100,
                  0,
                  1,
                  false
                )}
                % of {warrantyCyclesYearOne} warranty cycles/year
              </li>
            </ul>
          </li>
        </ul>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Month</th>
              <th>Cycles</th>
              <th>% daily</th>
              <th>% warranty</th>
              <th>Max charge</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(
              energyFlowMonthly as unknown as {
                [month: number]: AggregateEnergyFlow;
              }
            ).map(
              (
                [_month, energyFlow]: [string, AggregateEnergyFlow],
                index: number
              ) => {
                const fractionOfYear = getDaysInMonth(index + 1) / 366;
                const monthAnnualCycles = Math.round(366 * fractionOfYear);
                const monthWarrantyCycles = Math.round(
                  warrantyCyclesYearOne * fractionOfYear
                );
                return (
                  <tr key={index}>
                    <td>{months[index]}</td>
                    <td>
                      {formatNumber(
                        energyFlow.batteryDischarge /
                          1000.0 /
                          usableDischargeMwhPerCycle,
                        0,
                        0,
                        false
                      )}
                    </td>
                    <td>
                      {formatNumber(
                        (energyFlow.batteryDischarge /
                          1000.0 /
                          usableDischargeMwhPerCycle /
                          monthAnnualCycles) *
                          100,
                        0,
                        1,
                        false
                      )}
                      %
                    </td>
                    <td>
                      {formatNumber(
                        (energyFlow.batteryDischarge /
                          1000.0 /
                          usableDischargeMwhPerCycle /
                          monthWarrantyCycles) *
                          100,
                        0,
                        1,
                        false
                      )}
                      %
                    </td>
                    <td>
                      {formatNumber(
                        energyFlow.batteryMaxFinalCharge / 1000,
                        0,
                        3,
                        false
                      )}{" "}
                      (
                      {formatNumber(
                        (energyFlow.batteryMaxFinalCharge /
                          usableDischargeMwhPerCycle /
                          1000.0) *
                          100,
                        0,
                        0,
                        false
                      )}
                      %)
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </TooltipContent>
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 20px; // Buffer zone for mouse movement
  }
`;

const TooltipContent = styled.div`
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s linear 0.2s,
    opacity 0.2s;

  background: #f5f5f5;
  color: #000000;

  padding: 8px;
  border: 1px solid black;
  border-radius: 4px;
  white-space: pre-wrap;

  position: absolute;
  top: -8px;
  left: calc(100% + 10px);
  width: 450px;
  z-index: 99999;

  > ul {
    margin: 0;
  }

  ${TooltipContainer}:hover & {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
    pointer-events: auto;
  }
`;

export default BatteryTooltip;
