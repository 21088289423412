import { BatterySpec } from "@inrange/building-manager-api-client/models-site";

export const formatBattery = (spec: BatterySpec) => {
  const modelPrefix = spec.modelName
    ? `${spec.modelName} x ${spec.count} - `
    : "";
  if (spec.capacityMwh <= 1) {
    return `${modelPrefix}${(spec.powerMw * 1000).toFixed(1)} kW / ${(spec.capacityMwh * 1000).toFixed(1)} kWh`;
  }
  return `${modelPrefix}${spec.powerMw} MW / ${spec.capacityMwh} MWh`;
};
