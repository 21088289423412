const SITE_ORG_LABELS = {
  landlord: "Landlord",
  tenant: "Tenant",
  ownerOccupier: "Owner Occupied",
  agent: "Agent",
  broker: "Broker",
};

const SITE_STATUS_LABELS = {
  lease: "Lease",
  license: "License",
  none: "Not selected",
};

const USAGE_PROFILE_LABELS = {
  standard: "Daytime peak",
  evCharging: "Daytime only + EV",
  flat: "24hr usage",
  distribution: "Distribution",
};

const USAGE_DAYS_LABELS = {
  ALL: "Every day",
  WEEKDAYS: "Weekdays",
  CLOSED_SUNDAY: "Closed Sundays",
};

const PPA_TYPE_LABELS = {
  fixed: "Fixed",
  indexed: "Indexed",
};

export {
  PPA_TYPE_LABELS,
  SITE_ORG_LABELS,
  SITE_STATUS_LABELS,
  USAGE_DAYS_LABELS,
  USAGE_PROFILE_LABELS,
};
