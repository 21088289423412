import styled from "styled-components";
import { inrangeScoreStatusColor } from "../utils/siteScoresData";
import { siteStatusMap } from "../utils/siteStatusConstants";
import SiteScore from "./SiteScore";
import Tooltip from "./Tooltip";

const IRRTooltipText =
  "InRange score is calculated based on a number of factors, including the site’s potential IRR, payback length, likelihood of sufficient network availability, proximity of other InRange sites, and the characteristics of other sites in your portfolio.";

const SiteStatus = ({
  score,
  siteStatus,
  siteOperationalDateEpochSeconds,
}: {
  score?: number;
  siteStatus?: string;
  siteOperationalDateEpochSeconds?: number | null;
}) => {
  if (siteStatus === "operational") {
    return null;
  }

  const nowEpochSeconds = Math.floor(Date.now() / 1000);
  const siteOperationalDate = new Date(
    (siteOperationalDateEpochSeconds ?? 0) * 1000
  );
  const siteOperationalDateString = siteOperationalDate.toLocaleString(
    "default",
    {
      month: "short",
      year: "numeric",
    }
  );

  return (
    <StatusContainer>
      {siteStatus === "notStarted" ? (
        score && (
          <Tooltip text={IRRTooltipText} position={"bottom"}>
            <SiteScore
              siteScore={score}
              statusColor={inrangeScoreStatusColor[score]}
            />
          </Tooltip>
        )
      ) : (
        <>
          {siteStatus && (
            <StatusBox>
              {siteStatusMap[siteStatus].map((text, index) => (
                <span key={index}>{text}</span>
              ))}
            </StatusBox>
          )}
          {siteOperationalDateEpochSeconds &&
            siteOperationalDateEpochSeconds > nowEpochSeconds && (
              <StatusBox>
                <span>Est. operational</span>
                <span>{siteOperationalDateString}</span>
              </StatusBox>
            )}
        </>
      )}
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const StatusBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  font-size: 14px;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 8px;
  border: none;
  width: 160px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.grey200};
`;

export default SiteStatus;
