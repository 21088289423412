import React from "react";
import styled from "styled-components";
import { ColumnNoDefaults, FlexNoDefaults } from "../layout/Flex";

interface ModalViewProps {
  id?: string;
  title?: string;
  description?: JSX.Element;
  children: React.ReactNode;
  fontWeight?: string;
  fontSize?: string;
  titlePadding?: string;
  centerTitle?: boolean;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  width?: string;
}

const ModalView: React.FC<ModalViewProps> = ({
  id,
  title,
  description,
  children,
  fontWeight,
  fontSize,
  titlePadding,
  centerTitle,
  height,
  minHeight,
  maxHeight,
  width,
}) => {
  return (
    <ModalBackdrop $justifyContent={"center"} $alignItems={"center"}>
      <ModalContent
        id={id}
        $height={height}
        $minHeight={minHeight}
        $maxHeight={maxHeight}
        $width={width}
      >
        {title && (
          <ModalTitle
            $fontWeight={fontWeight}
            $fontSize={fontSize}
            $textAlign={centerTitle ? "center" : "default"}
            $titlePadding={titlePadding}
          >
            {title}
          </ModalTitle>
        )}
        {description && <ModalDescription>{description}</ModalDescription>}
        {children}
      </ModalContent>
    </ModalBackdrop>
  );
};

export default ModalView;

const ModalBackdrop = styled(FlexNoDefaults)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.16);
  padding: 16px;
  z-index: 1005;
  backdrop-filter: blur(2px);
`;

const ModalTitle = styled.div<{
  $fontWeight?: string;
  $fontSize?: string;
  $textAlign?: string;
  $titlePadding?: string;
}>`
  font-weight: ${(props) => (props.$fontWeight ? props.$fontWeight : 700)};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : "24px")};
  text-align: ${(props) => (props.$textAlign ? props.$textAlign : "default")};
  letter-spacing: -0.03em;
  color: #1a1d1f;
  padding-top: ${(props) => (props.$fontSize ? "16px" : "32px")};
  ${(props) =>
    props.$titlePadding &&
    `
    padding: ${props.$titlePadding};
  `}
`;

const ModalDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #000000;
  max-width: 611px;
  padding-bottom: 16px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 16px;

  p {
    margin: 0;
  }
`;

const ModalContent = styled(ColumnNoDefaults)<{
  $minHeight?: string;
  $maxHeight?: string;
  $height?: string;
  $width?: string;
}>`
  background-color: rgba(255, 255, 255);
  padding: 20px;
  border-radius: 8px;
  position: relative;
  padding: 24px;
  margin: 0 auto;
  max-height: 90%;
  overflow: auto;
  ${(props) =>
    props.$minHeight &&
    `
    min-height: ${props.$minHeight};
  `}
  ${(props) =>
    props.$maxHeight &&
    `
    max-height: ${props.$maxHeight};
  `}
  ${(props) =>
    props.$height &&
    `
    height: ${props.$height};
  `}
  ${(props) =>
    props.$width &&
    `
    width: ${props.$width};
  `}
  ${(props) =>
    !props.$width &&
    `
    max-width: 800px;
  `}
`;
