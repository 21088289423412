import { useSite } from "@inrange/building-manager-api-client";
import {
  Modal,
  ModalView,
  firstNameFromEmail,
} from "@inrange/theme-components";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { formatFieldName } from "./ActivityLogMap";

const timeFormats = {
  full: {
    day: "numeric",
    year: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
    timeZoneName: "short",
  },
  date: {
    day: "numeric",
    year: "numeric",
    month: "short",
  },
};

const timeDetails: Record<string, keyof typeof timeFormats> = {
  hubspotUpdateTime: "full",
  pvDesignSystemLastUpdatedAt: "full",
  commerciallyOperationalDateEpochSeconds: "date",
  leaseLengthDateEpochSeconds: "date",
};

const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;

const timeFromEpochString = (time: string | number, value = "") => {
  if (testMode) {
    return "(fixed-time-for-testing)";
  }
  const intTime = Number(time);
  const dateTime = DateTime.fromSeconds(intTime)
    .toUTC()
    .toLocaleString(timeFormats[timeDetails[value]] || timeFormats.full);
  return dateTime;
};

const formatSdmMatches = (
  lossFactorsByKey: Record<string, any>,
  siteId: string,
  formatFieldName: (
    lossFactorsByKey: Record<string, any>,
    fieldName: string
  ) => string,
  detail: any[]
) => {
  if (detail.length === 0) {
    return "[]";
  }
  return (
    <ul>
      {detail.map((item, index) => {
        const isBuy = item["buyerId"] === siteId;
        if (testMode) {
          if (item["buyerId"]) {
            item["buyerId"] = "fixed-id-for-testing";
          }
          if (item["sellerId"]) {
            item["sellerId"] = "fixed-id-for-testing";
          }
        }
        return (
          <li key={index}>
            {isBuy ? "Buy match" : "Sell match"}
            <ul>
              {Object.entries(item)
                .sort(([a, _], [b, __]) => {
                  const aName = formatFieldName(
                    lossFactorsByKey,
                    `sdmMatches.${a}`
                  );
                  const bName = formatFieldName(
                    lossFactorsByKey,
                    `sdmMatches.${b}`
                  );
                  return aName.localeCompare(bName);
                })
                .map(([key, value]) => (
                  <li key={key}>
                    {formatFieldName(lossFactorsByKey, `sdmMatches.${key}`)}:{" "}
                    {formatDetail(
                      lossFactorsByKey,
                      siteId,
                      formatFieldName,
                      key,
                      value
                    )}
                  </li>
                ))}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};

const formatDetail = (
  lossFactorsByKey: Record<string, any>,
  siteId: string,
  formatFieldName: (
    lossFactorsByKey: Record<string, any>,
    fieldName: string
  ) => string,
  fieldName: string,
  detail: any
): string | JSX.Element => {
  if (typeof detail === "boolean") {
    return String(detail);
  } else if (fieldName in timeDetails) {
    return detail ? timeFromEpochString(detail, fieldName) : "(no value)";
  } else if (Array.isArray(detail)) {
    if (fieldName === "sdmMatches") {
      return formatSdmMatches(
        lossFactorsByKey,
        siteId,
        formatFieldName,
        detail
      );
    } else {
      if (typeof detail[0] === "object") {
        return (
          <ol>
            {detail.map((item, index) => {
              return (
                <li key={index}>
                  {JSON.stringify(item, undefined, 1).replaceAll('"', "")}
                </li>
              );
            })}
          </ol>
        );
      }
      return `[${detail.join(", ")}]`;
    }
  }
  if (detail !== null && typeof detail === "object") {
    return JSON.stringify(detail, undefined, 1) || "(no value)";
  }
  return detail || "(no value)";
};

const removeUnchangedArrayItems = (detail: { before: any[]; after: any[] }) => {
  const beforeArray = detail.before || [];
  const afterArray = detail.after || [];
  // This implementation is O(n^2) but the arrays are expected to be small
  // A better implementation would hash the values and compare the hashes
  // but this would be slightly more complex code and not worth it for small arrays
  const commonItems = beforeArray.filter((item) =>
    afterArray.some((otherItem) => isEqual(item, otherItem))
  );
  detail.before = beforeArray.filter(
    (item) => !commonItems.some((otherItem) => isEqual(item, otherItem))
  );
  detail.after = afterArray.filter(
    (item) => !commonItems.some((otherItem) => isEqual(item, otherItem))
  );
};

const ActivityLog = ({
  siteId,
  createdAt,
  createdByName,
  createdByEmail,
}: {
  siteId: string;
  createdAt: string;
  createdByName: string;
  createdByEmail: string;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [logItem, setLogItem] = useState<{
    updatedByName: string;
    time: string;
    recalculated: Record<string, any>;
    userChange: Record<string, any>;
  }>({
    updatedByName: "",
    time: "",
    recalculated: {},
    userChange: {},
  });
  const { LOSS_FACTORS_BY_KEY: lossFactorsByKey, fetchSiteLog } = useSite({
    siteId,
    app: "admin",
  });
  const logData = fetchSiteLog.data.siteLog;

  useMemo(() => {
    for (const item of logData) {
      for (const [fieldName, detail] of Object.entries(item.userChange)) {
        if (fieldName === "sdmMatches") {
          removeUnchangedArrayItems(detail as { before: any[]; after: any[] });
        }
      }
    }
  }, [logData]);

  const sortFields = ([a, _]: [string, any], [b, __]: [string, any]) => {
    if (
      a.startsWith("generationLossFactors.") &&
      b.startsWith("generationLossFactors.")
    ) {
      const keyA = a.replace("generationLossFactors.", "");
      const keyB = b.replace("generationLossFactors.", "");
      return lossFactorsByKey[keyA].index - lossFactorsByKey[keyB].index;
    } else {
      const aName = formatFieldName(lossFactorsByKey, a);
      const bName = formatFieldName(lossFactorsByKey, b);
      return aName.localeCompare(bName);
    }
  };

  const activityLogRows = logData.map((item: any, index: number) => (
    <tr
      key={index}
      onClick={() => {
        setLogItem(item), setShowModal(true);
      }}
    >
      <td>{item.updatedByName}</td>
      <td>{timeFromEpochString(item.time)}</td>
      <td>
        <div>
          {Object.entries(
            item.userChange as Record<string, { before: any; after: any }>
          )
            .sort(sortFields)
            .map(([fieldName, detail], index) => (
              <div key={index}>
                {formatFieldName(lossFactorsByKey, fieldName)}:
                {formatDetail(
                  lossFactorsByKey,
                  siteId,
                  formatFieldName,
                  fieldName,
                  detail.before
                )}{" "}
                →{" "}
                {formatDetail(
                  lossFactorsByKey,
                  siteId,
                  formatFieldName,
                  fieldName,
                  detail.after
                )}
              </div>
            ))}
        </div>
      </td>
    </tr>
  ));

  const userChangesLogDetailRows = Object.entries(logItem.userChange)
    .sort(sortFields)
    .map(([fieldName, detail], index) => (
      <ActivityDetailRow
        siteId={siteId}
        formatFieldName={formatFieldName}
        fieldName={fieldName}
        detail={detail}
        key={index}
        lossFactorsByKey={lossFactorsByKey}
      />
    ));

  const recalulatedLogDetailRows = Object.entries(logItem.recalculated)
    .sort(sortFields)
    .map(([fieldName, detail], index) => (
      <ActivityDetailRow
        siteId={siteId}
        fieldName={fieldName}
        formatFieldName={formatFieldName}
        detail={detail}
        key={index}
        lossFactorsByKey={lossFactorsByKey}
      />
    ));

  return (
    <Card body className="mt-2">
      <h6 style={{ fontWeight: "bold" }}>Activity log</h6>
      <Card.Text className="text-secondary">
        {`Created on ${timeFromEpochString(createdAt)} by ${
          createdByName ?? firstNameFromEmail(createdByEmail)
        }`}
      </Card.Text>
      <div style={{ maxHeight: 300, overflow: "scroll" }}>
        <Table striped bordered hover style={{ fontSize: "90%" }}>
          <thead>
            <tr>
              <th>User</th>
              <th>Last modified date</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>{activityLogRows}</tbody>
        </Table>
      </div>
      {showModal ? (
        <Modal>
          <ModalView
            title={
              logItem.updatedByName + " on " + timeFromEpochString(logItem.time)
            }
            fontWeight="500"
            fontSize="20px"
            minHeight="450px"
          >
            <hr></hr>
            <div
              style={{
                overflow: "auto",
              }}
            >
              <Table
                striped
                bordered
                hover
                style={{ fontSize: "90%", width: "651px" }}
              >
                <thead>
                  <tr>
                    <th onClick={() => setShowModal(true)}>Value</th>
                    <th>Before</th>
                    <th>After</th>
                  </tr>
                </thead>
                <tbody>{userChangesLogDetailRows}</tbody>
              </Table>
              {recalulatedLogDetailRows && (
                <>
                  <h6 style={{ fontWeight: "bold" }}>Recalculations</h6>
                  <Table
                    striped
                    bordered
                    hover
                    style={{
                      fontSize: "90%",
                      width: "651px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th onClick={() => setShowModal(true)}>Value</th>
                        <th>Before</th>
                        <th>After</th>
                      </tr>
                    </thead>
                    <tbody>{recalulatedLogDetailRows}</tbody>
                  </Table>
                </>
              )}
            </div>
            <hr></hr>
            <div style={{ textAlign: "right" }}>
              <Button variant="primary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </div>
          </ModalView>
        </Modal>
      ) : null}
    </Card>
  );
};

const ActivityDetailRow = ({
  lossFactorsByKey,
  siteId,
  formatFieldName,
  fieldName,
  detail,
}: {
  lossFactorsByKey: Record<string, any>;
  siteId: string;
  formatFieldName: (
    lossFactorsByKey: Record<string, any>,
    fieldName: string
  ) => string;
  fieldName: string;
  detail: { before: any; after: any };
}) => {
  const detailBefore = formatDetail(
    lossFactorsByKey,
    siteId,
    formatFieldName,
    fieldName,
    detail.before
  );
  const detailAfter = formatDetail(
    lossFactorsByKey,
    siteId,
    formatFieldName,
    fieldName,
    detail.after
  );
  return (
    <tr>
      <td style={{ width: "40%" }}>
        {formatFieldName(lossFactorsByKey, fieldName)}
      </td>
      <td style={{ whiteSpace: "pre-wrap" }}>{detailBefore}</td>
      <td style={{ whiteSpace: "pre-wrap" }}>{detailAfter}</td>
    </tr>
  );
};

export default ActivityLog;
