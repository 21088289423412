import { useSiteFileUpload } from "@inrange/building-manager-api-client";
import {
  NullableAllowStringForNumbers,
  PartialSiteAllowStringValues,
  SiteAllowStringValues,
  SiteFile,
} from "@inrange/building-manager-api-client/models-site";
import { reversed, sortBy } from "@inrange/calculations/utils.ts";
import { ChangeEvent, useState } from "react";
import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap";
import { epochSecondsToDate } from "./utils";

const SiteFiles = ({
  site,
  siteFiles,
  onFileAdded,
  onFileDeleted,
  setSaveDisabled,
}: {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
  siteFiles: SiteFile[];
  onFileAdded: (file: NullableAllowStringForNumbers<SiteFile>) => void;
  onFileDeleted: (file: NullableAllowStringForNumbers<SiteFile>) => void;
  setSaveDisabled: (disabled: boolean) => void;
}) => {
  const [fileType, setFileType] = useState("reading");

  const { uploadSiteFile } = useSiteFileUpload(site.id);

  const onUpload = (e: ChangeEvent<HTMLInputElement>, fileType: string) => {
    const file = e.target.files?.[0];

    if (file) {
      setSaveDisabled(true);
      const newReportFile = {
        fileType,
        fileName: file.name,
      };
      uploadSiteFile.mutate(
        {
          file,
          siteFile: newReportFile,
        },
        {
          onSettled: () => setSaveDisabled(false),
          onSuccess: onFileAdded,
          onError: (error) => {
            console.error("Error uploading report", error);
          },
        }
      );
    }
  };

  const uploadInProgress = uploadSiteFile.isLoading;

  return (
    <Card
      body
      className="mt-2"
      style={{ maxHeight: "600px", overflow: "scroll" }}
    >
      <Form.Label>
        <strong>Site readings / statements / invoices</strong>
      </Form.Label>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Select
              value={fileType || ""}
              onChange={(e) => setFileType(e.target.value)}
              data-testid={"site-file-type"}
            >
              <option value={"reading"}>Reading</option>
              <option value={"invoice"}>Invoice</option>
              <option value={"statement"}>Statement</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md="auto">
          <Form.Group
            className="mb-3"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Control
              type="file"
              id="site-file-upload"
              style={{ display: "none" }}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf"
              onChange={(e) =>
                onUpload(
                  e as unknown as ChangeEvent<HTMLInputElement>,
                  fileType
                )
              }
            />
            <Stack direction="horizontal" gap={3}>
              <label
                htmlFor="site-file-upload"
                className="btn btn-success"
                data-testid={"site-file-upload"}
              >
                Add file
              </label>
              <div>{uploadInProgress && <>(Uploading...)</>}</div>
            </Stack>
          </Form.Group>
        </Col>
      </Row>
      {siteFiles
        .filter(
          (file) =>
            file.fileType === "reading" ||
            file.fileType === "statement" ||
            file.fileType === "invoice"
        )
        .sort(
          reversed(
            sortBy(
              (file) => file.fileType + "," + (file.date || file.issueDate || 0)
            )
          )
        )
        .map((file) => (
          <Row key={file.fileGuid}>
            <Row>
              <Col md="4">
                {file.fileName} <b>{!file.fileVersionId && " (unsaved)"}</b>{" "}
              </Col>
              <Col md={file.fileType === "reading" ? "3" : "6"}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    data-testid={`${file.fileName}-description`}
                    value={file.description || ""}
                    onChange={(e) =>
                      onFileAdded({
                        ...file,
                        description: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              {file.fileType == "reading" && (
                <Col md="3">
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="date"
                      onClick={(e) => {
                        const element = e.target;
                        if ("showPicker" in HTMLInputElement.prototype) {
                          // showPicker() is supported. Chrome 99+ and Safari 16+
                          // see https://caniuse.com/?search=showPicker
                          (element as HTMLInputElement).showPicker();
                        }
                      }}
                      data-testid={`${file.fileName}-date`}
                      value={epochSecondsToDate(file.date)}
                      onChange={(e) => {
                        const date = new Date(e.target.value);
                        const invalidDate = isNaN(date as unknown as number);
                        const epochSeconds = invalidDate
                          ? null
                          : date.getTime() / 1000; // if the date was cleared, set to null, otherwise getTime returns milliseconds, but we want seconds
                        onFileAdded({
                          ...file,
                          date: epochSeconds,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              )}
              <Col md="2">
                <Button
                  className="ms-3"
                  variant="danger"
                  size="sm"
                  onClick={() => onFileDeleted(file)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
            <Row>
              {file.fileType == "invoice" && (
                <>
                  <Col md="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Invoice Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={file.invoiceNumber || ""}
                        onChange={(e) =>
                          onFileAdded({
                            ...file,
                            invoiceNumber: e.target.value,
                          })
                        }
                        data-testid={`${file.fileName}-invoice-number`}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Invoice Amount</Form.Label>
                      <Form.Control
                        type="text"
                        value={file.invoiceAmount || ""}
                        onChange={(e) =>
                          onFileAdded({
                            ...file,
                            invoiceAmount: e.target.value,
                          })
                        }
                        data-testid={`${file.fileName}-invoice-amount`}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Issue Date</Form.Label>
                      <Form.Control
                        type="date"
                        onClick={(e) => {
                          const element = e.target;
                          if ("showPicker" in HTMLInputElement.prototype) {
                            // showPicker() is supported. Chrome 99+ and Safari 16+
                            // see https://caniuse.com/?search=showPicker
                            (element as HTMLInputElement).showPicker();
                          }
                        }}
                        value={epochSecondsToDate(file.issueDate)}
                        onChange={(e) => {
                          const date = new Date(e.target.value);
                          const invalidDate = isNaN(date as unknown as number);
                          const epochSeconds = invalidDate
                            ? null
                            : date.getTime() / 1000; // if the date was cleared, set to null, otherwise getTime returns milliseconds, but we want seconds
                          onFileAdded({
                            ...file,
                            issueDate: epochSeconds,
                          });
                        }}
                        data-testid={`${file.fileName}-issue-date`}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Due Date</Form.Label>
                      <Form.Control
                        type="date"
                        onClick={(e) => {
                          const element = e.target;
                          if ("showPicker" in HTMLInputElement.prototype) {
                            // showPicker() is supported. Chrome 99+ and Safari 16+
                            // see https://caniuse.com/?search=showPicker
                            (element as HTMLInputElement).showPicker();
                          }
                        }}
                        value={epochSecondsToDate(file.invoiceDueDate)}
                        onChange={(e) => {
                          const date = new Date(e.target.value);
                          const invalidDate = isNaN(date as unknown as number);
                          const epochSeconds = invalidDate
                            ? null
                            : date.getTime() / 1000; // if the date was cleared, set to null, otherwise getTime returns milliseconds, but we want seconds
                          onFileAdded({
                            ...file,
                            invoiceDueDate: epochSeconds,
                          });
                        }}
                        data-testid={`${file.fileName}-due-date`}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
              {file.fileType == "statement" && (
                <>
                  <Col md="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Statement Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={file.statementNumber || ""}
                        onChange={(e) =>
                          onFileAdded({
                            ...file,
                            statementNumber: e.target.value,
                          })
                        }
                        data-testid={`${file.fileName}-statement-number`}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Statement Amount</Form.Label>
                      <Form.Control
                        type="text"
                        value={file.statementAmount || ""}
                        onChange={(e) =>
                          onFileAdded({
                            ...file,
                            statementAmount: e.target.value,
                          })
                        }
                        data-testid={`${file.fileName}-statement-amount`}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Issue Date</Form.Label>
                      <Form.Control
                        type="date"
                        onClick={(e) => {
                          const element = e.target;
                          if ("showPicker" in HTMLInputElement.prototype) {
                            // showPicker() is supported. Chrome 99+ and Safari 16+
                            // see https://caniuse.com/?search=showPicker
                            (element as HTMLInputElement).showPicker();
                          }
                        }}
                        value={epochSecondsToDate(file.issueDate)}
                        onChange={(e) => {
                          const date = new Date(e.target.value);
                          const invalidDate = isNaN(date as unknown as number);
                          const epochSeconds = invalidDate
                            ? null
                            : date.getTime() / 1000; // if the date was cleared, set to null, otherwise getTime returns milliseconds, but we want seconds
                          onFileAdded({
                            ...file,
                            issueDate: epochSeconds,
                          });
                        }}
                        data-testid={`${file.fileName}-issue-date`}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
            <hr />
          </Row>
        ))}
    </Card>
  );
};

export default SiteFiles;
