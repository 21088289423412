import {
  SdmMatchConfig,
  SdmSiteOffer,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import {
  formatCurrency,
  formatPercentage,
} from "@inrange/theme-components/formatting";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatNumber2dp } from "./utils";

const buyOfferLargeEnoughForThisSite = (
  sdm: any,
  minMarketplaceMatchSize: number
) => {
  return sdm.buy.config.volume > minMarketplaceMatchSize;
};

const buyOfferLargeEnoughForOtherSite = (sdm: any) => {
  return sdm.buy.config.volume > sdm.dest.minMarketplaceMatchSize;
};

const sellOfferLargeEnoughForThisSite = (
  sdm: any,
  minMarketplaceMatchSize: number
) => {
  return sdm.sell.config.volume > minMarketplaceMatchSize;
};

const sellOfferLargeEnoughForOtherSite = (sdm: any) => {
  return sdm.sell.config.volume > sdm.dest.minMarketplaceMatchSize;
};

const SdmOfferRow: React.FC<{
  site: Site;
  sdmSite: SdmSiteOffer;
  isOwnerOccupied: boolean;
  isWired: boolean;
  setSelectedAddOffer: (offer: SdmMatchConfig | undefined) => void;
  minMarketplaceMatchSize: number;
}> = ({
  site,
  sdmSite,
  isOwnerOccupied,
  isWired,
  setSelectedAddOffer,
  minMarketplaceMatchSize,
}) => {
  const sdmData = sdmSite;
  const destinationSite = sdmData.dest;
  const buyOffer = sdmData.buy;
  const sellOffer = sdmData.sell;

  const orgNames = destinationSite.orgs
    .sort()
    .map((name: string, index: number) => <div key={index}>• {name}</div>);

  const availableToSell = isWired
    ? site.energyFlowAnnual.exported + site.energyFlowAnnual.curtailed
    : site.energyFlowAnnual.exported;

  const sellPerc =
    availableToSell > 0 ? sellOffer.config.volume / availableToSell : 0;
  const sellRemotePerc =
    destinationSite.gridImport > 0
      ? sellOffer.config.volume / destinationSite.gridImport
      : 0;
  const buyPerc =
    site.energyFlowAnnual.gridImport > 0
      ? buyOffer.config.volume / site.energyFlowAnnual.gridImport
      : 0;
  const buyRemotePerc =
    destinationSite.exported > 0
      ? buyOffer.config.volume / destinationSite.exported
      : 0;

  const isSellOfferLargeEnoughForThisSite = sellOfferLargeEnoughForThisSite(
    sdmData,
    minMarketplaceMatchSize
  );
  const isSellOfferLargeEnoughForOtherSite =
    sellOfferLargeEnoughForOtherSite(sdmData);

  const isBuyOfferLargeEnoughForThisSite = buyOfferLargeEnoughForThisSite(
    sdmData,
    minMarketplaceMatchSize
  );
  const isBuyOfferLargeEnoughForOtherSite =
    buyOfferLargeEnoughForOtherSite(sdmData);

  return (
    <tr>
      <td>
        <Link to={`/site/${destinationSite.id}/sdm`} target="_blank">
          {destinationSite.name.length > 60
            ? destinationSite.name.substring(0, 60) + "..."
            : destinationSite.name}
        </Link>
        {destinationSite.is_scenario ? (
          <>
            <br />
            (Excluded from customer-facing SDM)
          </>
        ) : (
          ""
        )}
        <br />
        {destinationSite.dno} / {destinationSite.sub}
      </td>
      {isWired && <td>{formatNumber2dp(destinationSite.km)}</td>}
      <td>{orgNames}</td>
      <td align="right">
        <div>{formatNumber2dp(sellOffer.config.volume)} kWh</div>
        {sellOffer.config.volume > 0 && (
          <>
            <div
              style={{
                color: isSellOfferLargeEnoughForThisSite ? "#000" : "#F00",
              }}
            >
              {formatPercentage(sellPerc, 2)} of this site{"'"}s excess{" "}
              {!isSellOfferLargeEnoughForThisSite && (
                <div style={{ fontSize: 12, whiteSpace: "nowrap" }}>
                  (Less than: {formatNumber2dp(minMarketplaceMatchSize)} kWh)
                </div>
              )}
            </div>
            <div
              style={{
                color: isSellOfferLargeEnoughForOtherSite ? "#000" : "#F00",
              }}
            >
              {formatPercentage(sellRemotePerc, 2)} of buyer{"'"}s unmet demand{" "}
              {!isSellOfferLargeEnoughForOtherSite && (
                <div style={{ fontSize: 12, whiteSpace: "nowrap" }}>
                  (Less than:{" "}
                  {formatNumber2dp(destinationSite.minMarketplaceMatchSize)} kWh
                  )
                </div>
              )}
            </div>
          </>
        )}
      </td>
      <td align="right">
        {sellOffer.config.volume === 0
          ? "N/A"
          : sellOffer.config.tariff.toFixed(4)}
      </td>
      {!isOwnerOccupied && (
        <>
          <td align="right">
            {sellOffer.config.volume === 0
              ? "N/A"
              : formatPercentage(
                  sellOffer.financialModels.landlord.license.irr as number,
                  2
                )}
          </td>
          <td align="right">
            {sellOffer.config.volume === 0
              ? "N/A"
              : formatPercentage(
                  (sellOffer.financialModels.landlord.license.irr as number) -
                    (site.financialModels.landlord.license.irr as number),
                  2,
                  false,
                  true
                )}
          </td>
        </>
      )}
      {isOwnerOccupied && (
        <>
          <td align="right">
            {sellOffer.config.volume === 0
              ? "N/A"
              : formatPercentage(
                  sellOffer.financialModels.ownerOccupier.license.irr as number,
                  2
                )}
          </td>
          <td align="right">
            {sellOffer.config.volume === 0
              ? "N/A"
              : formatPercentage(
                  (sellOffer.financialModels.ownerOccupier.license
                    .irr as number) -
                    (site.financialModels.ownerOccupier.license.irr as number),
                  2,
                  false,
                  true
                )}
          </td>
        </>
      )}
      <td>
        <Button
          variant="primary"
          onClick={() => {
            setSelectedAddOffer(sellOffer.config);
          }}
          disabled={sellOffer.config.volume === 0}
          style={{ padding: "0.375rem 1rem", width: "100%" }}
          size="sm"
        >
          Sell
        </Button>
      </td>
      <td align="right">
        <div>{formatNumber2dp(buyOffer.config.volume)} kWh</div>
        {buyOffer.config.volume > 0 && (
          <>
            <div
              style={{
                color: isBuyOfferLargeEnoughForThisSite ? "#000" : "#F00",
              }}
            >
              {formatPercentage(buyPerc, 2)} of this site{"'"}s unmet demand{" "}
              {!isBuyOfferLargeEnoughForThisSite && (
                <div style={{ fontSize: 12, whiteSpace: "nowrap" }}>
                  (Less than: {formatNumber2dp(minMarketplaceMatchSize)} kWh )
                </div>
              )}
            </div>
            <div
              style={{
                color: isBuyOfferLargeEnoughForOtherSite ? "#000" : "#F00",
              }}
            >
              {formatPercentage(buyRemotePerc, 2)} of seller{"'"}s excess{" "}
              {!isBuyOfferLargeEnoughForOtherSite && (
                <div style={{ fontSize: 12, whiteSpace: "nowrap" }}>
                  (Less than:{" "}
                  {formatNumber2dp(destinationSite.minMarketplaceMatchSize)} kWh
                  )
                </div>
              )}
            </div>
          </>
        )}
      </td>
      <td align="right">
        {buyOffer.config.volume === 0
          ? "N/A"
          : formatCurrency(
              buyOffer.config.volume * buyOffer.config.tariff,
              site.currencyCode,
              2
            )}
      </td>
      {isOwnerOccupied && (
        <>
          <td align="right">
            {calculateSavings(buyOffer, site, "ownerOccupier")}
          </td>
          <td align="right">
            {calculateSavingsDelta(buyOffer, site, "ownerOccupier")}
          </td>
        </>
      )}
      {!isOwnerOccupied && (
        <>
          <td align="right">{calculateSavings(buyOffer, site, "tenant")}</td>
          <td align="right">
            {calculateSavingsDelta(buyOffer, site, "tenant")}
          </td>
        </>
      )}
      <td>
        <Button
          variant="primary"
          onClick={() => {
            setSelectedAddOffer(buyOffer.config);
          }}
          disabled={buyOffer.config.volume === 0}
          style={{ padding: "0.375rem 1rem", width: "100%" }}
          size="sm"
        >
          Buy
        </Button>
      </td>
    </tr>
  );
};

const calculateSavings = (
  buyOpportunity: any,
  site: any,
  ownership: string
) => {
  const isTenant = ownership === "tenant";
  const savingsYearOne = isTenant
    ? buyOpportunity.financialModels[ownership].savings
    : buyOpportunity.financialModels[ownership][site.activeInvestmentModel]
        .savings;

  if (buyOpportunity.config.volume === 0) {
    return "N/A";
  } else {
    return formatCurrency(savingsYearOne, site.currencyCode, 2, false);
  }
};

const calculateSavingsDelta = (
  buyOpportunity: any,
  site: any,
  ownership: string
) => {
  const isTenant = ownership === "tenant";
  const savingsYearOne = isTenant
    ? buyOpportunity.financialModels[ownership].savings
    : buyOpportunity.financialModels[ownership][site.activeInvestmentModel]
        .savings;

  if (buyOpportunity.config.volume === 0) {
    return "N/A";
  } else {
    return formatCurrency(
      savingsYearOne -
        (isTenant
          ? site.financialModels[ownership].savings
          : site.financialModels[ownership][site.activeInvestmentModel]
              .savings),
      site.currencyCode,
      2,
      false,
      true
    );
  }
};

export default SdmOfferRow;
