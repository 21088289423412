import axiosConfig from "./axiosConfig";

const postSiteBatteryAnalysis = async (
  authToken,
  page,
  onlySyntheticBatteries,
  payload
) => {
  const { data } = await axiosConfig.post(
    `/site/preview/battery_analysis?page=${page}&onlySyntheticBatteries=${onlySyntheticBatteries}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default postSiteBatteryAnalysis;
