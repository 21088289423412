import React from "react";
import styled from "styled-components";

const SiteCallToActionLine: React.FC<{
  noPvSystemSelected: boolean;
  hhDataUploaded: boolean;
  name: string;
  onLinkClick: () => void;
}> = ({ noPvSystemSelected, hhDataUploaded, name, onLinkClick }) => {
  if (!noPvSystemSelected && hhDataUploaded) {
    return null;
  }

  const headerText = `Site ${noPvSystemSelected ? "data" : "demand"} is based on an initial assessment`;
  const headerTextSuffix = hhDataUploaded
    ? ", and is subject to on-site survey and validation."
    : ". For best results, ";
  const hrefHHData = `mailto:support@inrange.io?subject=I've attached my half hourly demand as a CSV for site "${name}"`;

  return (
    <>
      <SiteCallToActionLineParagraph>
        {headerText}
        {headerTextSuffix}
        {!hhDataUploaded && (
          <>
            <SiteCallToActionLineLink href={hrefHHData} onClick={onLinkClick}>
              send us your HH demand data
            </SiteCallToActionLineLink>
            .
          </>
        )}
      </SiteCallToActionLineParagraph>
    </>
  );
};

export default SiteCallToActionLine;

const SiteCallToActionLineParagraph = styled.p`
  font-size: 14px;
`;

const SiteCallToActionLineLink = styled.a`
  color: ${({ theme }) => theme.colors.greyFaint};
`;
