import React from "react";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";

interface LoadingProps {
  label: string | React.ReactNode;
  height?: string;
}

const Loading = ({ label, height = "100vh" }: LoadingProps) => {
  return (
    <Container
      className={`d-flex flex-column justify-content-center align-items-center`}
      style={{ height: height }}
    >
      <Spinner />
      <span data-testid="loading-label" className="align-middle mt-3">
        {label}{" "}
      </span>
    </Container>
  );
};

export default Loading;
