/*
  formatPercentage(0.1234) => '12.3%'
  formatPercentage(0.1234, 2) => '12.34%'
*/
const formatPercentage = (
  percentage: number,
  precision: number = 1,
  truncateOneHundred: boolean = false,
  showPlus: boolean = false
): string => {
  const result = `${(percentage * 100).toFixed(precision)}%`;
  if (truncateOneHundred && result === `${(1.0 * 100).toFixed(precision)}%`) {
    return "100%";
  }
  const plus = showPlus && percentage > 0 ? "+" : "";
  return plus + result;
};

export default formatPercentage;
