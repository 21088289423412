import React from "react";
import styled from "styled-components";
import circleTick from "../images/icons/circleTick.svg";
import circleUntick from "../images/icons/circleUntick.svg";

const RadioButton: React.FC<{
  option: string;
  selectedOption: string;
  onChange: (option: string) => void;
  testid: string;
}> = ({ option, selectedOption, onChange, testid }) => {
  return (
    <Box onClick={() => onChange(option)} data-testid={testid}>
      <RadioImg src={selectedOption === option ? circleTick : circleUntick} />
      <TextContainer>
        <Text>{option}</Text>
      </TextContainer>
    </Box>
  );
};

const RadioBox: React.FC<{
  options: string[];
  selectedOption: string;
  onOptionChange: (option: string) => void;
  testid: string;
}> = ({ options, selectedOption, onOptionChange, testid }) => {
  return (
    <Container>
      {options.map((option, index) => (
        <RadioButton
          option={option}
          selectedOption={selectedOption}
          onChange={onOptionChange}
          key={index}
          testid={`${testid}-${index}`}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const RadioImg = styled.img`
  padding-left: 10px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  min-width: 110px;
  background-color: #ffffff;
  border: 0.643403px solid #dfdfdf;
  border-radius: 5.14722px;
  padding-right: 16px;
  cursor: pointer;
`;

const TextContainer = styled.div`
  display: flex;
  padding: 9px;
  padding-left: 10px;
  padding-right: 0px;
  margin-bottom: 1px;
  align-items: center;
`;

const Text = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.57px;
  align-items: center;
  color: ${({ theme }) => theme.colors.dark};
`;

export default RadioBox;
