import {
  Building,
  getBuildingCoordinates,
} from "@inrange/building-manager-api-client/models-site";
import { Map, StyledGeoJsonGroup } from "@inrange/theme-components/mapping";
import * as Sentry from "@sentry/react";
import { Feature, LineString, Polygon } from "geojson";
import L, { latLngBounds, LatLngBounds } from "leaflet";
import "leaflet-editable";
import "leaflet.path.drag";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useMemo, useState } from "react";
import Form from "react-bootstrap/Form";
import { UseQueryResult } from "react-query";
import {
  getStyle,
  outlineGreenStyle,
} from "../PVCustomObjects/pvcustomobjectmap-utils";

interface PvMapProps {
  siteId: string;
  siteCenter: [number, number];
  buildings: Building[];
  panelsUrl: string | undefined;
  customObjects: (Feature<Polygon> | Feature<LineString>)[];
  usePresignedUrlData: <T>(
    siteId: string,
    urlType: string,
    url: string | undefined
  ) => UseQueryResult<T>;
}

const PvMap: React.FC<PvMapProps> = ({
  siteId,
  siteCenter,
  buildings,
  panelsUrl,
  customObjects,
  usePresignedUrlData,
}) => {
  const panelObjectsQuery = usePresignedUrlData<string[][]>(
    siteId,
    "panel_objects",
    panelsUrl
  );

  const [hideMap, setHideMap] = useState<boolean>(false);
  const [map, setMap] = useState<L.Map | undefined>();

  useEffect(() => {
    const bounds: LatLngBounds = latLngBounds([
      L.latLng(siteCenter[0], siteCenter[1]),
    ]);
    for (const building of buildings) {
      const buildingCoordinates = getBuildingCoordinates(building);
      for (const buildingCoordinate of buildingCoordinates[0]) {
        bounds.extend(L.latLng(buildingCoordinate[1], buildingCoordinate[0]));
      }
    }
    if (map) {
      try {
        map.flyToBounds(bounds);
      } catch (e) {
        // We know this can happen, possibly related to https://github.com/Leaflet/Leaflet/issues/9527
        Sentry.captureException(e);
      }
    }
  }, [map, siteCenter, buildings]);

  const styledGeoJsonGroups: StyledGeoJsonGroup[] = useMemo(() => {
    const buildingsGeoJson = buildings.map((building) => {
      const geoJsonBuilding: Polygon = {
        ...building.geometry,
        coordinates: getBuildingCoordinates(building),
        type: "Polygon",
      };
      return {
        key: building.id,
        geoJson: geoJsonBuilding,
        color: outlineGreenStyle.color,
      };
    });

    if (!panelObjectsQuery.isSuccess) {
      return [
        { geoJsons: buildingsGeoJson, name: "Buildings", key: "buildings" },
      ];
    }

    const panelsGeoJson = (panelObjectsQuery?.data || [[]])
      .map((buildingPanels) => {
        return buildingPanels.map((panel: string, index: number) => {
          return {
            key: `panel-${index}-${panel}`,
            geoJson: JSON.parse(panel) as Polygon,
            color: "#4578de",
            fillColor: "#ADD8E6",
            fillOpacity: 1,
            weight: 1,
          };
        });
      })
      .flat();

    const objectsGeoJson = customObjects.map((object, index) => {
      const objectColor = getStyle(object.properties!.type, false).color;
      return {
        key: `object-${index}-${object}`,
        geoJson: object,
        color: objectColor,
        fillColor: objectColor,
        fillOpacity: 0.4,
        weight: 1,
      };
    });

    return [
      { geoJsons: buildingsGeoJson, name: "Buildings", key: "buildings" },
      { geoJsons: objectsGeoJson, name: "Roof objects", key: "roofobjects" },
      { geoJsons: panelsGeoJson, name: "Panels", key: "panels" },
    ];
  }, [
    buildings,
    customObjects,
    panelObjectsQuery?.data,
    panelObjectsQuery.isSuccess,
  ]);

  if (panelsUrl === undefined || !panelObjectsQuery.isSuccess) {
    return (
      <>
        <Form.Label>
          <strong>Site Map:</strong>
        </Form.Label>
        <div>
          <i>Not yet available.</i>;
        </div>
      </>
    );
  }

  return (
    <>
      <Form.Label>
        <strong>Site Map:</strong> (
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "rgb(13, 110, 253)",
          }}
          onClick={() => setHideMap(!hideMap)}
        >
          {hideMap ? "show" : "hide"}
        </span>
        )
      </Form.Label>
      {!hideMap && (
        <div>
          <Map
            width={"100%"}
            height={"500px"}
            lat={siteCenter[0]}
            lon={siteCenter[1]}
            zoom={20}
            styledGeoJsonGroups={styledGeoJsonGroups}
            setMap={setMap}
            useGoogleMapsBaseLayer={true}
            tooltipText={undefined}
          />
        </div>
      )}
    </>
  );
};

export default PvMap;
