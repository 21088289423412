import { Form } from "react-bootstrap";

const BorderedCheckbox = ({
  value,
  label,
  formName,
  selectedOption,
  setSelectedOption,
  dataTestId,
  disabled,
}: {
  value: string;
  label: string;
  formName: string;
  selectedOption: string;
  setSelectedOption: (value: string) => void;
  dataTestId?: string;
  disabled?: boolean;
}) => {
  const handleRadioButtonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div
      className="mb-3"
      style={{
        border: "1px solid #DFDFDF",
        backgroundColor: "#FFFFFF",
        color: "black",
        padding: "8px 8px 2px",
        borderRadius: "4px",
        marginRight: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
        cursor: "pointer",
        flexGrow: 1,
        ...(selectedOption.toLowerCase() === value.toLowerCase() && {
          backgroundColor: "#F0F0F0",
          border: "1px solid #00022F",
        }),
      }}
      onClick={disabled ? undefined : () => setSelectedOption(value)}
      data-testid={dataTestId}
    >
      <Form.Check
        inline
        type="radio"
        name={formName}
        value={value}
        checked={selectedOption.toLowerCase() === value.toLowerCase()}
        onChange={handleRadioButtonChange}
        label={label}
        disabled={disabled}
      />
    </div>
  );
};

export default BorderedCheckbox;
