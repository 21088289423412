import React from "react";

import styled from "styled-components";
import AccountMenu from "./AccountMenu";
import BreadCrumbs, { BreadCrumb } from "./Breadcrumbs";
import SiteStatus from "./SiteStatus";

interface HeaderProps {
  crumbs: BreadCrumb[];
  userEmail: string;
  score?: number;
  siteStatus?: string;
  siteOperationalDateEpochSeconds?: number | null;
}

const Header: React.FC<HeaderProps> = ({
  crumbs,
  userEmail,
  score,
  siteStatus,
  siteOperationalDateEpochSeconds,
}) => {
  return (
    <HeaderContainer id={"layout-header"}>
      <BreadCrumbs crumbs={crumbs} />
      <SiteStatus
        score={score}
        siteStatus={siteStatus}
        siteOperationalDateEpochSeconds={siteOperationalDateEpochSeconds}
      />
      <AccountMenu userEmail={userEmail} />
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  background: white;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px 20px;
  height: 72px;
`;
