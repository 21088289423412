import styled, { css } from "styled-components";
import Card from "./Card";
import Tooltip from "./Tooltip";

const MetricsRow = ({
  metrics,
  ...props
}: {
  metrics: {
    label: string;
    value: string;
    note: string;
    tooltipText?: string;
    tight?: boolean;
  }[];
}) => {
  return (
    <MetricsRowContainer {...props}>
      {metrics.map((metric, index) => (
        <MetricCard key={index} {...metric} />
      ))}
    </MetricsRowContainer>
  );
};

export default MetricsRow;

const MetricsRowContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
`;

const MetricCard = ({
  label,
  value,
  note,
  tooltipText,
  ...props
}: {
  label: string;
  value: string;
  note: string;
  tooltipText?: string;
  tight?: boolean;
}) => {
  let updatedProps: { tight?: boolean; fontSize?: string } = { ...props };

  if (!props.tight) {
    updatedProps = { ...props, fontSize: "12px" };
  } else {
    updatedProps = { ...props, fontSize: "14px" };
  }
  return (
    <MetricCardContainer {...updatedProps}>
      {tooltipText && (
        <MetricRowTooltip>
          <Tooltip text={tooltipText} />
        </MetricRowTooltip>
      )}
      <MetricTitle tooltip={!tooltipText}>{label}</MetricTitle>
      <MetricValue>{value}</MetricValue>
      <MetricNote>{note}</MetricNote>
    </MetricCardContainer>
  );
};

const MetricCardContainer = styled(Card)`
  display: flex;
  flex-grow: 1;
`;

const MetricRowTooltip = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MetricTitle = styled.p<{ tooltip: boolean }>`
  line-height: 20px;
  font-weight: 600;
  max-height: 25px;
  color: ${({ theme }) => theme.colors.blueSolid};
  margin-top: 0px;
  max-width: 170px;
  ${({ tooltip }) =>
    tooltip &&
    css`
      margin-top: 20px;
    `}
`;

const MetricValue = styled.p`
  margin: 16px 0 0;
  letter-spacing: 0.01em;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
`;

const MetricNote = styled.p`
  margin: 8px 0 0;
  font-size: 12px;
  max-width: 170px;
  color: ${({ theme }) => theme.colors.grey800};
`;
