import styled from "styled-components";

const PortfolioTab = ({
  tabName,
  icon,
  siteCount,
  active,
}: {
  tabName: string;
  icon: string;
  siteCount?: number;
  active: boolean;
}) => {
  return (
    <PortfolioTabContainer active={active}>
      <PortfolioTabImage src={icon} alt="tab-icon" />
      <PortfolioContentAlign>
        <PortfolioTabName data-testid={`tab-${tabName.replace(" ", "-")}`}>
          {tabName}
        </PortfolioTabName>
        {siteCount && (
          <PortfolioSiteCount active={active}>{siteCount}</PortfolioSiteCount>
        )}
      </PortfolioContentAlign>
    </PortfolioTabContainer>
  );
};

const PortfolioTabContainer = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 10px;
  height: 40px;
  border-radius: 5px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.dark};
  ${({ active, theme }) =>
    active && {
      color: "#ffffff",
      backgroundColor: `${theme.colors.blueTabColor}`,
      fontWeight: 700,
    }}
`;

const PortfolioTabName = styled.div``;

const PortfolioContentAlign = styled.div`
  display: flex;
  align-items: baseline;
`;

const PortfolioTabImage = styled.img`
  image-rendering: auto;
  margin-right: 8px;
  width: 21px;
  height: 20px;
`;

const PortfolioSiteCount = styled.div<{ active: boolean }>`
  background-color: #dfdfdf;
  padding: 1px 3px;
  border-radius: 2px;
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.dark};
  ${({ active, theme }) =>
    active && {
      color: `${theme.colors.blueTabColor}`,
    }};
`;

export default PortfolioTab;
