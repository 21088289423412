import React from "react";
import styled from "styled-components";

const Card: React.FC<{
  children: React.ReactNode;
  dark?: boolean;
  blue?: boolean;
}> = ({ children, dark, blue, ...props }) => {
  return (
    <CardContainer dark={dark} blue={blue} {...props}>
      {children}
    </CardContainer>
  );
};

const CardContainer = styled.div<{ dark?: boolean; blue?: boolean }>`
  background: white;
  border-radius: 8px;
  margin: 16px 8px;
  padding: 24px 16px 40px 24px;
  ${({ dark, theme }) =>
    dark && {
      backgroundColor: theme.colors.grey200,
    }}
  ${({ blue }) =>
    blue && {
      backgroundColor: "#00022F",
    }}
`;

export default Card;
