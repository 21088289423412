import styled from "styled-components";

const SiteScore = ({
  siteScore,
  statusColor,
}: {
  siteScore?: number;
  statusColor?: string;
}) => {
  return (
    <SiteScoreContainer>
      <SiteScoreInner statusColor={statusColor}>{siteScore}</SiteScoreInner>
    </SiteScoreContainer>
  );
};

export default SiteScore;

const SiteScoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SiteScoreInner = styled.p<{
  statusColor?: string;
  removePointer?: boolean;
}>`
  width: 48px;
  height: 48px;
  margin: 0 9px;
  border-radius: 50%;
  cursor: default;
  line-height: 40px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey100};
  background: ${({ theme, statusColor }) =>
    statusColor ? statusColor : theme.colors.primary};
  border: 4px solid ${({ theme }) => theme.colors.grey200};
`;
