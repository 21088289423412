import { BatterySpec } from "@inrange/building-manager-api-client/models-site";

export const formatNumber2dp = (
  value: number | string,
  useGrouping: boolean = true
): string => {
  return formatNumber(value, 2, 2, useGrouping);
};

export const formatNumber = (
  value: number | string,
  minimumNumFractionDigits: number,
  maximumFractionDigits: number,
  useGrouping: boolean = true
): string => {
  if (typeof value === "number") {
    if (value === 0) {
      // Always display negative 0 as positive 0
      // https://stackoverflow.com/questions/7223359/are-0-and-0-the-same
      value += 0;
    }
    const numberFormat = new Intl.NumberFormat("en-GB", {
      style: "decimal",
      minimumFractionDigits: minimumNumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
      //@ts-expect-error - TS doesn't know about the roundingMode option
      roundingMode: "trunc",
      useGrouping: useGrouping,
    });
    return numberFormat.format(value);
  }

  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) {
    return value;
  }

  const startsWithDecimal = value.startsWith(".");
  if (startsWithDecimal) {
    return value;
  }

  const endsWithDecimal = value.endsWith(".");

  const numberFormat = new Intl.NumberFormat("en-GB", {
    style: "decimal",
    maximumFractionDigits: maximumFractionDigits,
    //@ts-expect-error - TS doesn't know about the roundingMode option
    roundingMode: "trunc",
    useGrouping: useGrouping,
  });
  const formattedValue = numberFormat.format(parsedValue);

  const originalDecimals = value.split(".")[1]?.length || 0;
  const formattedDecimals = formattedValue.split(".")[1]?.length || 0;
  if (originalDecimals > maximumFractionDigits) {
    return formattedValue;
  }

  // Pad with zeros if needed
  if (originalDecimals > formattedDecimals) {
    const [whole, fraction = ""] = formattedValue.split(".");
    const paddedFraction = fraction.padEnd(originalDecimals, "0");
    return `${whole}.${paddedFraction}`;
  }

  return endsWithDecimal ? formattedValue + "." : formattedValue;
};

export const epochSecondsToDate = (
  epochSeconds: number | undefined | null
): string => {
  if (!epochSeconds) return "";
  const date = new Date(epochSeconds * 1000);
  return date.toISOString().split("T")[0];
};

export const currencyValue = (value: number, currencyCode: string): string => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const setMissingValuesZero = (objA: object, objB: object) => {
  for (const key in objA) {
    if (objB[key] === undefined) {
      objB[key] = 0;
    }
  }
  for (const key in objB) {
    if (objA[key] === undefined) {
      objA[key] = 0;
    }
  }
};

export const stripBatteryQuotes = (spec: BatterySpec) => {
  const { quotedCosts: _quotedCosts, ...rest } = spec;
  return rest;
};

export const parseAsNumber = (value: string | number | undefined): number => {
  if (typeof value === "number") {
    return value;
  }
  if (typeof value === "string") {
    return parseFloat(value);
  }
  return 0;
};

export const roundToDp = (value: number, dp: number): number => {
  return Math.round(value * Math.pow(10, dp)) / Math.pow(10, dp);
};
