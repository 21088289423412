import hash from "object-hash";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

const Deeds = ({
  deeds,
  onHover,
  isLoading,
  buildingCount,
  addDeedtoSite,
}: {
  deeds: Record<string, any>;
  onHover: (deed: any) => void;
  isLoading: boolean;
  buildingCount: number;
  addDeedtoSite: (deed: any) => void;
}) => {
  const sortedDeeds = Object.values(deeds).sort((a, b) => {
    return a.pro_name_1 < b.pro_name_1
      ? -1
      : a.pro_name_1 > b.pro_name_1
        ? 1
        : 0;
  });

  const onAddClick = (deed: any) => {
    addDeedtoSite(deed);
  };

  const deedRows = sortedDeeds.map((deed) => {
    return (
      <DeedRow
        deed={deed}
        key={hash(deed)}
        onHover={onHover}
        onAddClick={onAddClick}
      />
    );
  });

  const headerString = `${Object.keys(deeds).length} Deeds in the area overlapping the ${buildingCount} selected buildings`;
  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>{isLoading ? "Loading title deeds..." : headerString}</strong>
      </Form.Label>
      <div style={{ maxHeight: 300, overflow: "scroll" }}>
        <Table striped bordered hover style={{ fontSize: "90%" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{deedRows}</tbody>
        </Table>
      </div>
    </Card>
  );
};

const DeedRow = ({
  deed,
  onHover,
  onAddClick,
}: {
  deed: any;
  onHover: (deed: any) => void;
  onAddClick: (deed: any) => void;
}) => {
  return (
    <tr onMouseOver={() => onHover(deed)} onMouseOut={() => onHover({})}>
      <td>{deed.pro_name_1}</td>
      <td>{deed.pro1_addr1}</td>
      <td>{deed.tenure}</td>
      <td>
        <Button
          size="sm"
          variant="success"
          onClick={() => {
            onAddClick(deed);
          }}
        >
          Add
        </Button>
      </td>
    </tr>
  );
};

export default Deeds;
