import { useOrganisationList } from "@inrange/building-manager-api-client";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import { SiteOwnership } from "@inrange/building-manager-api-client/models-site";
import { sortBy, sortByAdditional } from "@inrange/calculations/utils.ts";
import { Icons } from "@inrange/theme-components/icons";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import getCustomerAppDomain from "../../../../utils/getCustomerAppDomain";
import { SITE_ORG_LABELS } from "../enums";

const BelongsTo = ({
  siteID,
  ownerships,
  setEditedOwnerships,
  onOwnerRemoved,
  setShowBelongsTo,
}: {
  siteID: string;
  ownerships: { orgID: string; name: string; ownership: string }[];
  setEditedOwnerships: (update: (previous: object) => void) => void;
  onOwnerRemoved: (owner: { orgID: string; ownership: string }) => void;
  setShowBelongsTo: (show: boolean) => void;
}) => {
  const { fetchOrganisations } = useOrganisationList();
  const [siteOwnerships, setSiteOwnerships] = useState(ownerships);

  const removeOwnership = (siteOrg) => {
    const updatedExistingOwners = siteOwnerships.filter(
      (existingSiteOrg) => existingSiteOrg.orgID !== siteOrg.orgID
    );
    if (updatedExistingOwners.length === 0) {
      setShowBelongsTo(false);
    }
    onOwnerRemoved(siteOrg);
    setSiteOwnerships(updatedExistingOwners);
  };

  const orgsById = (fetchOrganisations.data?.organisations || []).reduce(
    (acc: Record<string, Organisation>, org: Organisation) => {
      acc[org.id] = org;
      return acc;
    },
    {}
  );

  return (
    <Row style={{ padding: "0 12px" }}>
      <Form.Text muted>Belongs to:</Form.Text>
      <Table
        borderless
        style={{
          margin: "0 12px 16px 12px",
          width: "calc(100% - 24px)",
        }}
      >
        <tbody>
          {siteOwnerships
            .sort(
              sortByAdditional(
                (siteOrg) => siteOrg.name,
                sortBy((siteOrg) => siteOrg.ownership)
              )
            )
            .map((siteOrg, i) => (
              <BelongsToRow
                key={i}
                index={i}
                siteID={siteID}
                siteOrg={siteOrg}
                org={orgsById[siteOrg.orgID]}
                setEditedOwnerships={setEditedOwnerships}
                onOwnerRemoved={removeOwnership}
              />
            ))}
        </tbody>
      </Table>
    </Row>
  );
};

const BelongsToRow = ({
  index,
  siteID,
  siteOrg,
  org,
  setEditedOwnerships,
  onOwnerRemoved,
}: {
  index: number;
  siteID: string;
  siteOrg: SiteOwnership;
  org?: Organisation;
  setEditedOwnerships: (update: (previous: object) => void) => void;
  onOwnerRemoved: (owner: { orgID: string; ownership: string }) => void;
}) => {
  const [park, setPark] = useState(siteOrg.park);
  return (
    <tr>
      <td style={{ verticalAlign: "middle" }}>
        <strong style={{ padding: 0 }}>
          <a
            href={`/organisation/${siteOrg.orgID}/edit`}
            style={{ color: "black", textDecoration: "none" }}
            data-testid={`belongs-to-org-link-${index}`}
          >
            {siteOrg.name}
          </a>
        </strong>
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <div>{SITE_ORG_LABELS[siteOrg.ownership]}</div>
      </td>
      <td style={{ verticalAlign: "middle" }}>
        {org?.parks && org?.parks?.length > 0 && (
          <Form.Group controlId="parkEdit">
            <Form.Select
              value={park ?? ""}
              onChange={(e) => {
                setPark(e.target.value);
                setEditedOwnerships((previous) => {
                  return {
                    ...previous,
                    [siteOrg.orgID]: {
                      ...siteOrg,
                      park: e.target.value,
                    },
                  };
                });
              }}
            >
              <option value="">No park</option>
              {org.parks.sort().map((park) => (
                <option key={park} value={park}>
                  {park}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <a
          href={`${getCustomerAppDomain()}/org/${siteOrg.orgID}/site/${siteID}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#0d6efd",
            textDecoration: "none",
            cursor: "pointer",
          }}
          data-testid={`customer-app-site-link-${index}`}
        >
          Public site
          <img
            alt="view"
            src={Icons.shareBlue}
            style={{
              width: "17px",
              height: "17px",
              marginLeft: "8px",
              marginBottom: "2px",
              cursor: "pointer",
            }}
          ></img>
        </a>
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "right",
        }}
      >
        <div
          onClick={() => onOwnerRemoved(siteOrg)}
          style={{
            cursor: "pointer",
          }}
        >
          <img
            data-testid={`remove-owner-${index}`}
            alt="remove"
            src={Icons.trashcanRed}
            style={{
              width: "20px",
              height: "20px",
              marginLeft: "6px",
              cursor: "pointer",
            }}
          ></img>
        </div>
      </td>
    </tr>
  );
};

export default BelongsTo;
