import { KINDE_ENVIRONMENT } from "@inrange/auth";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createContext, ReactNode, useEffect, useState } from "react";
import Loading from "../routes/Loading";

interface User {
  name: string;
  email: string;
  family_name: string;
  given_name: string;
  picture: string;
  org_id?: string;
}

interface UserContextValue {
  user?: User;
  logout?: () => void;
}

const UserContext = createContext<UserContextValue>({});

const UserProvider = ({ children }: { children: ReactNode }) => {
  const {
    login,
    logout,
    isLoading: isAuthLoading,
    user: kindeUser,
    getOrganization,
  } = useKindeAuth();
  const [user, setUser] = useState<
    | {
        name: string;
        email: string;
        family_name: string;
        given_name: string;
        picture: string;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    if (!isAuthLoading && kindeUser) {
      setUser({
        name: `${kindeUser.given_name} ${kindeUser.family_name}`,
        email: kindeUser.email!,
        family_name: kindeUser.family_name!,
        given_name: kindeUser.given_name!,
        picture: kindeUser.picture!,
      });
    }
  }, [isAuthLoading, kindeUser]);

  if (!isAuthLoading && !kindeUser) {
    login({
      org_code: KINDE_ENVIRONMENT.KINDE_INRANGE_ORG_CODE,
      app_state: {
        redirectTo: window.location.pathname + window.location.search,
      },
    });
  }

  if (!user) {
    return <Loading label="Loading ..." />;
  }

  const organisation = getOrganization();
  if (
    organisation.orgCode !== KINDE_ENVIRONMENT.KINDE_INRANGE_ORG_CODE ||
    !user.email.endsWith("@inrange.io")
  ) {
    // Guard against a user who logs in to another org on the customer website
    // Without this setTimeout the logout gets stuck in a redirect loop for some reason
    setTimeout(() => {
      console.log("Fallback logout");
      logout();
    }, 100);
    return <Loading label="Loading ..." />;
  }

  return (
    <UserContext.Provider value={{ user, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
