import { useSite } from "@inrange/building-manager-api-client";
import {
  AggregateEnergyFlow,
  PartialSiteAllowStringValues,
  Site,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import { YearDayEnergyChart } from "@inrange/theme-components/charts";
import { useState } from "react";
import { Badge, Card, Col, Form, Row, Stack, Table } from "react-bootstrap";
import { useContextTS } from "../../../../../shared-components/src/context-utils";
import { SiteCalculationsContext } from "../../../../../shared-components/src/SiteCalculationsContext";
import { genEnergyDemandChartData } from "./chartDataFormatting";
import { USAGE_DAYS_LABELS, USAGE_PROFILE_LABELS } from "./enums";
import { formatNumber, formatNumber2dp, parseAsNumber } from "./utils";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Demand = ({
  site,
  setSite,
}: {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
}) => {
  const { errors, siteCalculations } = useContextTS(SiteCalculationsContext);
  const haveActualDemandData = site.halfHourlyDemand?.source === "hh_data";

  const demandCoefficient = haveActualDemandData
    ? (site.totalBuildingArea as number) > 0
      ? (siteCalculations.energyFlowAnnual?.demand || 0) /
        (site.totalBuildingArea as number)
      : 0
    : site.demandCoefficientKWhPerM2;

  const { fetchSiteValues } = useSite({
    app: "admin",
    enableNonEssentialQueries: true,
  });
  const [displayMonthlyTenantEnergyShare, setDisplayMonthlyTenantEnergyShare] =
    useState(false);
  const [displayDemandGraph, setDisplayDemandGraph] = useState(false);
  const [energyProcuredChartPeriod, setEnergyProcuredChartPeriod] = useState<
    "year" | "summer" | "winter"
  >("summer");

  const demandSource = haveActualDemandData ? (
    <Badge bg="success">Actual</Badge>
  ) : (
    <Badge bg="warning">Inferred</Badge>
  );

  const demandChartData = genEnergyDemandChartData(
    site as Site,
    energyProcuredChartPeriod
  );

  const renderDemandMetadata = () => {
    const metadata = site?.halfHourlyDemand?.metadata;
    const haveDemandMetadata =
      haveActualDemandData &&
      metadata?.latest_full_year_num_rows_customer_data !== undefined &&
      metadata?.latest_full_year_num_rows != undefined;
    if (!haveDemandMetadata) return null;

    const percentageRealData = (
      (parseAsNumber(metadata.latest_full_year_num_rows_customer_data) /
        parseAsNumber(metadata.latest_full_year_num_rows)) *
      100
    ).toFixed(2);
    return (
      <>
        <p>
          Metadata:{" "}
          <ul>
            <li>{percentageRealData}% of data directly from input HH data</li>
            <li>Input file: {metadata?.input_file ?? "Unknown"}</li>
            <li>
              Date file processed: {metadata?.date_of_analysis ?? "Unknown"}
            </li>
            {!!metadata?.site_mapping?.overrides?.existing_panels && (
              <li>
                Subtracted simulated generation from existing panels:{" "}
                {metadata?.site_mapping?.overrides?.existing_panels}
              </li>
            )}
          </ul>
        </p>
      </>
    );
  };

  const getDaylightPercentage = (
    energyFlow: AggregateEnergyFlow | undefined
  ) => {
    if (
      energyFlow === undefined ||
      energyFlow.daylightBehindMeter === undefined ||
      energyFlow.daylightDemand === undefined
    ) {
      return "N/A";
    }
    if (energyFlow.daylightDemand === 0) return "0";
    return (energyFlow.daylightBehindMeter / energyFlow.daylightDemand).toFixed(
      4
    );
  };

  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Demand</strong>
      </Form.Label>
      <Row className="d-flex flex-wrap">
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="buildingProfile">
            <Form.Label>Building profile</Form.Label>
            <Form.Select
              value={site.buildingProfile}
              onChange={(e) => {
                const newProfile = e.target.value;
                const profileData =
                  fetchSiteValues.data!.options.buildingProfiles[newProfile]!;
                setSite({
                  buildingProfile: newProfile,
                  demandCoefficientKWhPerM2: profileData.demandCoefficient,
                  profileShape: profileData.profileShape,
                  profileDays: profileData.profileDays,
                });
              }}
              data-testid={"buildingProfile"}
            >
              {Object.entries(
                fetchSiteValues.data!.options.buildingProfiles
              ).map(([profileValue, profileData]) => (
                <option key={profileValue} value={profileValue}>
                  {profileData.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="demandCoefficient">
            <Form.Label>Coefficient (kWh/m²)</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(demandCoefficient, 2, 8, false)}
              onChange={(e) => {
                setSite({
                  demandCoefficientKWhPerM2: formatNumber(
                    e.target.value,
                    2,
                    8,
                    false
                  ),
                });
              }}
              isInvalid={!!errors.demandCoefficientKWhPerM2}
              disabled={
                haveActualDemandData || site.buildingProfile === "noDemand"
              }
              data-testid={"demandCoefficient"}
            />
            <Form.Control.Feedback type="invalid">
              {errors.demandCoefficientKWhPerM2}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="totalBuildingArea">
            <Form.Label>Total building area (m²)</Form.Label>
            <Form.Control
              disabled
              value={formatNumber(site.totalBuildingArea, 2, 2)}
              data-testid={"totalBuildingArea"}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="annualDemand">
            <Form.Label>Annual demand (kWh)</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(
                siteCalculations.energyFlowAnnual?.demand || 0,
                2,
                2
              )}
              disabled
              data-testid={"annualDemand"}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="daylightDemand">
            <Form.Label>Daylight demand (kWh)</Form.Label>
            <Form.Control
              type="text"
              value={
                siteCalculations.energyFlowAnnual?.daylightDemand !== undefined
                  ? formatNumber2dp(
                      siteCalculations.energyFlowAnnual?.daylightDemand || 0
                    )
                  : "N/A"
              }
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="daylightEnergyShare">
            <Form.Label>Daylight fulfilled by on-site (%)</Form.Label>
            <Form.Control
              type="text"
              value={getDaylightPercentage(siteCalculations.energyFlowAnnual)}
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="consumedOnsite">
            <Form.Label>Jan daylight fulfilled by on-site (%)</Form.Label>
            <Form.Control
              type="text"
              value={
                siteCalculations.energyFlowMonthly?.[1] !== undefined
                  ? getDaylightPercentage(siteCalculations.energyFlowMonthly[1])
                  : "N/A"
              }
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="consumedOnsite">
            <Form.Label>July daylight fulfilled by on-site (%)</Form.Label>
            <Form.Control
              type="text"
              value={
                siteCalculations.energyFlowMonthly?.[7] !== undefined
                  ? getDaylightPercentage(siteCalculations.energyFlowMonthly[7])
                  : "N/A"
              }
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="consumedOnsite">
            <Form.Label>Total fulfilled by on-site (%)</Form.Label>
            <Form.Control
              type="text"
              value={((siteCalculations.energyFlowAnnual?.demand || 0) > 0
                ? (siteCalculations.energyFlowAnnual?.behindMeter || 0) /
                  (siteCalculations.energyFlowAnnual?.demand || 0)
                : 0
              ).toFixed(4)}
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="consumedOnsite">
            <Form.Label>Total fulfilled by IR (%)</Form.Label>
            <Form.Control
              type="text"
              value={((siteCalculations.energyFlowAnnual?.demand || 0) > 0
                ? ((siteCalculations.energyFlowAnnual?.behindMeter || 0) +
                    (siteCalculations.energyFlowAnnual?.networkImport || 0)) /
                  (siteCalculations.energyFlowAnnual?.demand || 0)
                : 0
              ).toFixed(4)}
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="tenantEnergyShare">
            <Form.Label>Tenant energy share (%)</Form.Label>
            <Form.Control
              type="text"
              value={
                siteCalculations.energyFlowAnnual?.tenantEnergyShare
                  ? siteCalculations.energyFlowAnnual?.tenantEnergyShare.toFixed(
                      4
                    )
                  : ""
              }
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="behindMeterBattery">
            <Form.Label>On-site (Battery) (%)</Form.Label>
            <Form.Control
              type="text"
              value={
                siteCalculations.energyFlowAnnual?.behindMeter
                  ? (
                      siteCalculations.energyFlowAnnual?.behindMeterBattery /
                      siteCalculations.energyFlowAnnual?.behindMeter
                    ).toFixed(4)
                  : ""
              }
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group>
            <Stack direction="horizontal">
              <Form.Label>
                <strong>Demand shape</strong>
              </Form.Label>
              <div className="ms-auto">{demandSource}</div>
            </Stack>
            {!(site.buildingProfile === "noDemand" || haveActualDemandData) && (
              <>
                <Form.Group className="mb-2" controlId="demandConsumptionTime">
                  <Form.Select
                    value={site.profileShape}
                    onChange={(e) => setSite({ profileShape: e.target.value })}
                  >
                    {Object.entries(USAGE_PROFILE_LABELS).map(
                      ([key, label]) => (
                        <option key={key} value={key}>
                          {label}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="demandConsumptionDays">
                  <Form.Select
                    value={site.profileDays}
                    onChange={(e) => setSite({ profileDays: e.target.value })}
                    data-testid={"demandConsumptionDays"}
                  >
                    {Object.entries(USAGE_DAYS_LABELS).map(([key, label]) => (
                      <option key={key} value={key}>
                        {label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </>
            )}
            {haveActualDemandData && !displayDemandGraph && (
              <div
                style={{
                  textAlign: "center",
                  color: "rgb(13, 110, 253)",
                }}
              >
                <a onClick={() => setDisplayDemandGraph(true)}>
                  Show demand graph
                </a>
              </div>
            )}
          </Form.Group>
        </Col>
        <Col>
          {displayMonthlyTenantEnergyShare ? (
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Tenant share (%)</th>
                  <th>
                    Daylight fulfilled <br />
                    by on-site(%)
                  </th>
                </tr>
              </thead>
              <tbody>
                {siteCalculations.monthlyTenantEnergyShare?.map(
                  (energyShare: number, index: number) => (
                    <tr key={index}>
                      <td>{months[index]}</td>
                      <td>{energyShare.toFixed(4)}</td>
                      <td>
                        {siteCalculations.energyFlowMonthly?.[index + 1] !==
                        undefined
                          ? getDaylightPercentage(
                              siteCalculations.energyFlowMonthly[index + 1]
                            )
                          : "N/A"}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          ) : (
            <div
              style={{
                textAlign: "left",
                color: "rgb(13, 110, 253)",
                paddingTop: "30px",
                paddingLeft: "20%",
              }}
            >
              <a
                onClick={() => setDisplayMonthlyTenantEnergyShare(true)}
                style={{
                  cursor: "pointer",
                }}
              >
                Show monthly tenant share / daylight
              </a>
            </div>
          )}
        </Col>
      </Row>
      {haveActualDemandData && displayDemandGraph && (
        <Row>
          <Col>
            <YearDayEnergyChart
              chartData={demandChartData}
              energyProcuredChartPeriod={energyProcuredChartPeriod}
              setEnergyProcuredChartPeriod={setEnergyProcuredChartPeriod}
              height={"350px"}
              $padding={"16px 0 0 0"}
              isGeneratingSite={false}
              isBatterySite={false}
            />
            {renderDemandMetadata()}
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default Demand;
